import React from "react";

// import {
//   og,
//   tw
// } from "../../services/imports/imagens";

import Navigation from "../../components/global/Navigation";
import Footer from "../../components/global/Footer";

import Header from "./sections/Header";

function Home() {
  return (
    <>
      {/* <img style={{ display: 'none' }} loading="lazy" alt="chevron right" src={og} />
      <img style={{ display: 'none' }} loading="lazy" alt="chevron right" src={tw} /> */}
      <Navigation />
      <Header />
      <Footer /> 
    </>
  );
}

export default Home;
