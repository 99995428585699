import { React, useState } from "react";
import {
  auth,
  OAuthProvider,
  signInWithPopup,
} from "../../../services/api/connection/FirebaseConfig";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";

import "../styles.css";

import { google, apple, eyeSlash, eye } from "../../../services/imports/icons";
import { useAuth } from "../../../services/context/Auth";
import { Navigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const isFormValid = email !== "" && password;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const { signInWithGoogle, updateUser, signed} = useAuth();
  
  // eslint-disable-next-line
  const [signInWithEmailAndPassword, user, load, error] = useSignInWithEmailAndPassword(auth);

   // Authentication with Google
  async function handleSignInWithGoogle() {
    setLoading(true)
    await signInWithGoogle();
    setLoading(false)
  }

  // Authentication with email and password
  async function signInWithEmail() {
    setLoading(true)
    try {
      const userCredential = await signInWithEmailAndPassword(email, password);
      const user = userCredential.user;
  
      sessionStorage.setItem("@AuthFirebase:token", user.accessToken);
      sessionStorage.setItem("@AuthFirebase:user", JSON.stringify(user));
      updateUser(user)
      setLoading(false)
    } catch (err) {
      console.error("Erro ao autenticar com email e senha", err);
      console.error("Erro ao autenticar", error);
      setLoading(false)
    }
  }

  const appleProvider = new OAuthProvider("apple.com");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Authentication with Apple
  const handleSignInWithApple = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      // eslint-disable-next-line
      const user = result.user;
    } catch (error) {
      console.error("Erro ao autenticar com Apple", error);
    }
  };

  if (!signed) {
  return (
    <section className="container-login">
      <div className="container login-box">
        <div className="login-brand">
          <a href="/">
            <p>ZIYØN BANK</p>
          </a>
        </div>
        <div className="login-inputs">
          <h1>Bem-vindo à ziyøn</h1>
          <form>
            <div className="mb-3">
              <label htmlFor="inputEmail1" className="form-label">
                Seu e-mail
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail1"
                placeholder="Digite seu e-mail"
                aria-describedby="emailHelp"
                onChange={(e) => setEmail(e.target.value)}
              />
              <div id="emailHelp" className="form-text">
                {error ? (
                  error.code === "auth/invalid-email" ? (
                    <p className="label-error error">
                      E-mail não está no formato correto.
                    </p>
                  ) : error.code === "auth/invalid-credential" ? (
                    <p className="label-error error">
                     Por favor, verifique seu e-mail e senha.
                    </p>
                  ) : error.code === "auth/user-not-found" ? (
                    <p className="label-error error">
                      E-mail não cadastrado. Tente novamente!
                    </p>
                  ) : null
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Sua senha
              </label>
              <div className="btn-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  id="inputPassword"
                  aria-describedby="passwordHelp"
                  placeholder="Informe sua senha"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  class="btn btn-outline-dark"
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    loading="lazy"
                    alt={passwordVisible ? "eyeSlash" : "eye"}
                    src={passwordVisible ? eyeSlash : eye}
                  />
                </button>
              </div>
              <div id="passwordHelp" className="form-text">
                {error ? (
                  error.code === "auth/wrong-password" ||
                  error.code === "auth/invalid-password" ? (
                    <p className="label-error error">
                      A senha fornecida está incorreta ou inválida.
                    </p>
                  ) : null
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-dark disable" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Aguarde...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-dark"
                disabled={!isFormValid}
                onClick={signInWithEmail}
              >
                Acessar minha conta
              </button>
            )}
          </form>
        </div>
        <div>
          <div className="login-divider">
            <div className="login-line"></div>
            <div className="our">Ou</div>
            <div className="login-line"></div>
          </div>
          <div className="btn-container">
            <div className="btn-brands">
              <img loading="lazy" alt="google" src={google} />
              <div onClick={handleSignInWithGoogle}>Acessar com o Google</div>
            </div>
            <div className="btn-brands">
              <img loading="lazy" alt="apple" src={apple} />
              <div onClick={handleSignInWithApple}>Acessar com o Apple</div>
            </div>
          </div>
        </div>
      </div>
      <div className="link-outside">
        <a href="/register">Criar minha conta ziyøn</a>
      </div>
    </section>
  );
} else {
  console.log("logado")
  return <Navigate to="/dashboard" />;
}
}

export default Login;
