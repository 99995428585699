import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

// import {
//   brandWhite,
//   arrowLeftRight,
//   menu,
//   chevronRightWhite,
//   envelope,
//   instagram,
//   whatsapp,
//   person,
//   personAdd,
//   paper,
//   bank,
// } from "../../services/imports/icons";

function Navigation() {
  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            ZIYØN (IØN) | CRYPTO
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div>
            Alto desempenho, rapidez e segurança, uma moeda criada para ser um investimento.
            <br />
            <br />
            Uma moeda <strong>moderna</strong> para um mundo moderno.
          </div>
          <h1 className="title">Minha conta</h1>
          <Link to="/login" data-bs-dismiss="offcanvas"
            aria-label="Close" target="_blank" rel="noopener noreferrer">
              <div className="box-link  mt-2">
                <div><img loading="lazy" alt="gym" src="" /><p>Entrar</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
            <Link to="/register" data-bs-dismiss="offcanvas"
            aria-label="Close" target="_blank" rel="noopener noreferrer">
              <div className="box-link  mt-2">
                <div><img loading="lazy" alt="gym" src="" /><p>Cadastrar</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
          <h1 className="title">Outros assuntos</h1>
          <div className="box-nav">
            <Link to="https://pancakeswap.finance/swap?outputCurrency=0x0c35c6D7c7A21f3299285840710437C50f608B19" data-bs-dismiss="offcanvas"
            aria-label="Close" target="_blank" rel="noopener noreferrer">
              <div className="box-link">
                <div><img loading="lazy" alt="loteria" src="" /><p>PancakeSwap</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
            <Link to="https://ziyon.fr" data-bs-dismiss="offcanvas"
            aria-label="Close">
              <div className="box-link mt-2">
                <div><img loading="lazy" alt="oficial" src="" /><p>Investir via ziyøn</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
            <Link to="/wallet" data-bs-dismiss="offcanvas"
            aria-label="Close">
            </Link>
          </div>
          <h1 className="title">Suporte</h1>
          <div className="box-nav">
            <Link to="https://wa.me/5511937077888" data-bs-dismiss="offcanvas"
            aria-label="Close" target="_blank" rel="noopener noreferrer">
              <div className="box-link">
                <div><img loading="lazy" alt="whatsapp" src="" /><p>WhatsApp</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
            <Link to="https://www.instagram.com/ziyon.br" data-bs-dismiss="offcanvas"
            aria-label="Close" target="_blank" rel="noopener noreferrer">
              <div className="box-link mt-2">
                <div><img loading="lazy" alt="instagram" src="" /><p>Instagram Ion</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
            <Link to="mailto:contact@ziyon.fr" data-bs-dismiss="offcanvas"
            aria-label="Close">
              <div className="box-link mt-2">
                <div><img loading="lazy" alt="e-mail" src="" /><p>contact@ziyon.fr</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
          </div>
          <h1 className="title">Documentação</h1>
          <div>
            <Link to="/whitepaper" data-bs-dismiss="offcanvas"
            aria-label="Close">
              <div className="box-link mt-2">
                <div><img loading="lazy" alt="doc" src="" /><p>Whitepaper</p></div> <img loading="lazy" alt="seta" src="" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <nav className="nav-global">
        <div className="container nav">
          <div className="brand">
            <a href="/">
              <p>ZIYØN BANK</p>
            </a>
          </div>
          <div className="right-nav">
            <Link className="xadrez" to="/">Soluções</Link>
            <Link className="xadrez" to="/" target="_blank" rel="noopener noreferrer">Investimentos</Link>
            <Link className="xadrez" to="https://wa.me/5511937077888" target="_blank" rel="noopener noreferrer">Suporte</Link>
            <Link className="sign-in" to="/login">Entrar</Link>
            <Link className="sign-up" to="/register">Criar conta</Link>
            {/* <a data-bs-toggle="offcanvas" href="#offcanvasMenu" role="button" aria-controls="offcanvasExample"></a> */}
            <a href="/">
              <div className="menu">
                <img className="" loading="lazy" alt="menu" src="" />
              </div>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navigation;
