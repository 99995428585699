import { React, useState } from "react";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import {
  auth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from "../../../services/api/connection/FirebaseConfig";
import createClientInFirestore from "../../../services/api/controller/CreateClientInFirestore";

import "../styles.css";

import {
  google,
  apple,
  eyeSlash,
  eye,
  exit,
  checkCircle,
} from "../../../services/imports/icons";

function Register() {
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const isFormValid = emailInput !== "" && passwordInput !== "" && isChecked;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const googleProvider = new GoogleAuthProvider();
  const appleProvider = new OAuthProvider("apple.com");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // eslint-disable-next-line
  const [createUserWithEmailAndPassword, use, load, error] =
    useCreateUserWithEmailAndPassword(auth);

  const getUserIP = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (err) {
      console.error("Erro ao obter o IP do usuário:", err);
      return null;
    }
  };

  const handleUserSignUp = async (user, email, password) => {
    setLoading(true);

    let permission = "client";

    try {
      const clientIp = await getUserIP();
      await createClientInFirestore(
        permission,
        user,
        clientIp,
        email,
        password
      );
      setSuccess(true);
      console.info("Conta criada com sucesso!");
    } catch (err) {
      console.error("Erro ao criar cliente no Firestore", err);
    } finally {
      setLoading(false);
    }
  };

// Authentication with email and password
  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        emailInput,
        passwordInput
      );
      const user = userCredential.user;
      await handleUserSignUp(user, emailInput, passwordInput);
    } catch (error) {
      console.error("Erro ao criar usuário com email e senha", error);
    }
  };

// Authentication with Google
  const handleSignUpWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      await handleUserSignUp(user, user.email, null);
    } catch (error) {
      console.error("Erro ao autenticar com Google", error);
    }
  };

// Authentication with Apple
  const handleSignUpWithApple = async () => {
    try {
      const result = await signInWithPopup(auth, appleProvider);
      const user = result.user;
      await handleUserSignUp(user, user.email, null);
    } catch (error) {
      console.error("Erro ao autenticar com Apple", error);
    }
  };

  return (
    <section className="container-login">
      <div className={success ? "dialog-box" : "display"}>
        <div className="dialog-box-title">
          <h1>Deu tudo certo!</h1>
          <a href="/login">
            <img loading="lazy" alt="x" src={exit} />
          </a>
        </div>
        <div className="dialog-box-body">
          <div className="success">
            <img loading="lazy" alt="success" src={checkCircle} />
            <p>
              <strong>Sua conta foi criada com sucesso!</strong>
            </p>
          </div>
          <p className="main-content">
            Cresça em seus próprios termos. Agora você pode comparar moedas,
            fazer investimentos entre outros serviços.
          </p>
        </div>
        <div className="dialog-box-footer">
          <a className="btn btn-dark" href="/login">
            Acessar agora!
          </a>
        </div>
      </div>

      <div className={success ? "display" : "container login-box"}>
        <div className="login-brand">
          <a href="/">
            <p>ZIYØN BANK</p>
          </a>
        </div>
        <div className="login-inputs">
          <h1>Bem-vindo à ziyøn</h1>
          <form>
            <div className="mb-3">
              <label htmlFor="inputEmail1" className="form-label">
                Seu e-mail
              </label>
              <input
                type="email"
                className="form-control"
                id="inputEmail1"
                placeholder="Digite seu e-mail"
                aria-describedby="emailHelp"
                onChange={(e) => setEmailInput(e.target.value)}
              />
              <div id="emailHelp" className="form-text">
                {error ? (
                  error.code === "auth/email-already-in-use" ? (
                    <p className="label-error error">
                      E-mail já está cadastrado. Digite outro!
                    </p>
                  ) : null
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="inputPassword" className="form-label">
                Sua senha
              </label>
              <div className="btn-group">
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  id="inputPassword"
                  aria-describedby="passwordHelp"
                  placeholder="Informe sua senha"
                  onChange={(e) => setPasswordInput(e.target.value)}
                />
                <button
                  class="btn btn-outline-dark"
                  type="button"
                  onClick={togglePasswordVisibility}
                >
                  <img
                    loading="lazy"
                    alt={passwordVisible ? "eyeSlash" : "eye"}
                    src={passwordVisible ? eyeSlash : eye}
                  />
                </button>
              </div>
              <div id="passwordHelp" className="form-text">
                {error ? (
                  error.code === "auth/weak-password" ? (
                    <p className="label-error error">
                      Senha fraca. Deve ter no mínimo 6 dígitos!
                    </p>
                  ) : null
                ) : (
                  <p className="label-error">
                    Sua senha deve ter no mínimo 6 dígitos
                  </p>
                )}
              </div>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="check"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label className="form-check-label" htmlFor="check">
                Ao criar uma conta, eu concordo com os{" "}
                <a href="/">Termos de Serviço</a> e com a{" "}
                <a href="/">Política de Privacidade</a> da ziyøn.
              </label>
            </div>
            {loading ? (
              <button type="submit" className="btn btn-dark disable" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Aguarde...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-dark"
                onClick={handleSignUp}
                disabled={!isFormValid}
              >
                Criar minha conta
              </button>
            )}
          </form>
        </div>
        <div>
          <div className="login-divider">
            <div className="login-line"></div>
            <div className="our">Ou</div>
            <div className="login-line"></div>
          </div>
          <div className="btn-container">
            <div className="btn-brands">
              <img loading="lazy" alt="google" src={google} />
              <div onClick={handleSignUpWithGoogle}>Criar com o Google</div>
            </div>
            <div className="btn-brands">
              <img loading="lazy" alt="apple" src={apple} />
              <div onClick={handleSignUpWithApple}>Criar com o Apple</div>
            </div>
          </div>
        </div>
      </div>
      <div className={success ? "display" : "link-outside"}>
        <a href="/login">Já tenho uma conta ziyøn</a>
      </div>
    </section>
  );
}

export default Register;
