import { firestore, setDoc, doc } from "../connection/FirebaseConfig";
import generateClientCode from "./GenerateClientCode";

// Function to create the client in Firestore
const createClientInFirestore = async (
  permission,
  user,
  clientIp,
  email,
  password
) => {
  const clientCode = await generateClientCode();

  const clientData = {
    access: {
      client_permission: permission,
      client_auth: user.uid,
      client_2fa: false,
      client_code: clientCode,
      client_ip: clientIp,
      client_last_login: null,
      client_password: password,
      client_user: email,
      client_verified: user.emailVerified,
      creation_date: user.metadata.creationTime,
    },
    address: {
      client_country: "",
      client_state: "",
      client_city: "",
      client_district: "",
      client_street: "",
      client_number: "",
      client_complement: "",
      client_postal_code: "",
    },
    client_data: {
      client_birth: "",
      client_cnpj: "",
      client_cpf: "",
      client_id: "",
      client_name: user.displayName,
      client_nickname: "",
      client_status: "inativo",
      client_img: "",
    },
    client_policy: {
      aml: {
        acceptance_date: null,
        aml_verification: false,
      },
      kyc: {
        acceptance_date: null,
        kyc_verification: false,
      },
      privacy: {
        acceptance_date: null,
        client_terms: true,
      },
      service: {
        acceptance_date: null,
        client_policy: true,
      },
    },
    contact: {
      client_email: email,
      client_phone: "",
      client_whatsapp: "",
      client_instagram: "",
      client_facebook: "",
    },
    investment: {
      client_investment: [],
      investment_profile: {
        client_favorites: [],
        client_limit: "0",
        client_type: "",
      },
    },
    wallet: {},
  };

  await setDoc(doc(firestore, "User", clientCode), clientData);
};

export default createClientInFirestore;
