import React from "react";
import "./styles.css";

function Header() {
  return (
    <section className="container section-header">
        <p>Estamos em construção, <strong>aguarde!</strong></p>
    </section>
  );
}

export default Header;
