import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Access/Login";
import Register from "../pages/Access/Register";
import Dashboard from "../pages/Dashboard";
import { useAuth } from "../services/context/Auth";

const PrivateRoutes = () => {
  const { user } = useAuth();
  return user ? <Outlet /> : <Navigate to="/" />;
};

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/dashboard" element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
