import React from "react";
import "./styles.css";

import Navigation from "../../components/dashboard/Navigation";

import Home from "./sections/Home";

function Dashboard() {
  return (
    <section className="ct-dashboard">
      <div className="ct-nav">
        <Navigation />
      </div>
      <div className="ct-content">
        <Home />
      </div>
    </section>
  );
}

export default Dashboard;
