import {
  firestore,
  collection,
  getDocs,
  query,
  where,
} from "../connection/FirebaseConfig";
import generateRandomCode from "./GenerateRandomCode";

// Function to create the complete code (ZN-24-100-XXXXX-X)
const generateClientCode = async () => {
  let codeExists = true;
  let clientCode = "";

  // Get the current year
  const currentYear = new Date().getFullYear().toString().slice(-2);

  while (codeExists) {
    const randomCode = generateRandomCode();
    clientCode = `ZN-${currentYear}-100-${randomCode}`;

    // Check in Firestore if the code already exists
    const q = query(
      collection(firestore, "BankTesting", "Access", "Clients"),
      where("access.client_code", "==", clientCode)
    );
    const querySnapshot = await getDocs(q);
    codeExists = !querySnapshot.empty;
  }

  return clientCode;
};

export default generateClientCode;
