import React from "react";
import "./styles.css";

import { useAuth } from "../../../services/context/Auth";

import { out, person, boy, chartPie, arrowRightShort } from "../../../services/imports/icons";

function Navigation() {
  const { user, signOut } = useAuth();
  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        tabIndex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            ZIYØN BANK
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          Alto desempenho, rapidez e segurança, uma moeda criada para ser um
          investimento.
        </div>
      </div>
      <nav className="nav-dashboard">
        <div className="nav-dashboard-header">
          <div className="dashboard-header-brand">
            <h1>ZIYON SAS</h1>
            <p> Private Bank</p>
          </div>
          <div class="card"><div class="card-body">Indicar para um amigo <img loading="lazy" alt="profile" src={arrowRightShort} /></div></div>
        </div>
        <div className="nav-dashboard-body">
          <div class="accordion">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button btn-dash" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                  <div>
                    <img loading="lazy" alt="profile" src={chartPie} />
                    <p>Dashboard</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show">
                <div class="accordion-body">
                <a class="dropdown-item active" href="/dashboard"><span class="bullet-dot"></span>Inicio</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Moedas</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Private equity</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Transferências</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Depósito</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Saque</a>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                  <div>
                    <img loading="lazy" alt="profile" src={boy} />
                    <p>Serviços</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Finanças</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Calculadora</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Cotação</a>
                <a class="dropdown-item remove-space-stuff" href="/dashboard"><span class="bullet-dot"></span>Cursos</a>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-body-titles">
            <h2>Documentação</h2>
          </div>
          <div class="accordion">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                  <div>
                    <img loading="lazy" alt="profile" src={chartPie} />
                    <p>Doc -1</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Inicio</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Moedas</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Private equity</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Transferências</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Depósito</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Saque</a>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false" aria-controls="panelsStayOpen-collapseFour">
                  <div>
                    <img loading="lazy" alt="profile" src={boy} />
                    <p>Doc -2</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Finanças</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Calculadora</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Cotação</a>
                <a class="dropdown-item remove-space-stuff" href="/dashboard"><span class="bullet-dot"></span>Cursos</a>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-body-titles">
            <h2>Configurações</h2>
          </div>
          <div class="accordion" >
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFive" aria-expanded="false" aria-controls="panelsStayOpen-collapseFive">
                  <div>
                    <img loading="lazy" alt="profile" src={chartPie} />
                    <p>Config -1</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseFive" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Inicio</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Moedas</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Private equity</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Transferências</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Depósito</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Saque</a>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false" aria-controls="panelsStayOpen-collapseSix">
                  <div>
                    <img loading="lazy" alt="profile" src={boy} />
                    <p>Config -1</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Finanças</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Calculadora</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Cotação</a>
                <a class="dropdown-item remove-space-stuff" href="/dashboard"><span class="bullet-dot"></span>Cursos</a>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-body-titles">
              <h2>Suporte</h2>
            </div>
          <div class="accordion">
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false" aria-controls="panelsStayOpen-collapseSeven">
                  <div>
                    <img loading="lazy" alt="profile" src={chartPie} />
                    <p>Suport -1</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Inicio</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Moedas</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Private equity</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Transferências</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Depósito</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Saque</a>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseEight" aria-expanded="false" aria-controls="panelsStayOpen-collapseEight">
                  <div>
                    <img loading="lazy" alt="profile" src={boy} />
                    <p>Suport -2</p>
                  </div>
                </button>
              </h2>
              <div id="panelsStayOpen-collapseEight" class="accordion-collapse collapse">
                <div class="accordion-body">
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Finanças</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Calculadora</a>
                <a class="dropdown-item" href="/dashboard"><span class="bullet-dot"></span>Cotação</a>
                <a class="dropdown-item remove-space-stuff" href="/dashboard"><span class="bullet-dot"></span>Cursos</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nav-dashboard-footer">
          <div className="profile-user">
            <div className="ct-img-profile" data-bs-toggle="dropdown" aria-expanded="false">
              <img loading="lazy" alt="profile" src={person} />
            </div>
            <ul class="dropdown-menu">
              <li className="dropdown-profile">
                <div className="dropdown-img-profile">
                  <img loading="lazy" alt="profile" src={person} />
                </div>
                <div>
                  <p className="name-user">{user.displayName || "Joe Doe"}</p>
                  <p className="legend-user">{user.displayName || "Premium"}</p>
                </div>
              </li>
              <li className="my-stuff">
                <a class="dropdown-item" href="/dashboard">Meu Perfil</a>
                <a class="dropdown-item" href="/dashboard">Investimentos</a>
                <a class="dropdown-item" href="/dashboard">Assinaturas</a>
                <a class="dropdown-item remove-space-stuff" href="/dashboard">Indicações</a>
              </li>
              <li className="system-stuff">
                <a class="dropdown-item" href="/dashboard">Idioma</a>
                <a class="dropdown-item" href="/dashboard">Configurações</a>
                <a onClick={() => signOut()} class="dropdown-item" href="/dashboard">Sai da ziyon</a>
              </li>
            </ul>
            <div>
              <p className="name-user">{user.displayName || "Joe Doe"}</p>
              <p className="legend-user">{user.displayName || "Premium"}</p>
            </div>
          </div>
          <div onClick={() => signOut()} className="exit">
            <img loading="lazy" alt="profile" src={out} />
          </div>
        </div>
        <div></div>
      </nav>
    </>
  );
}

export default Navigation;
