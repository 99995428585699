import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, get, child } from "firebase/database";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAU8g3EiztCNzdrHwDc8M0cvQMrWmsPcbo",
  authDomain: "ziyon-bank.firebaseapp.com",
  projectId: "ziyon-bank",
  storageBucket: "ziyon-bank.appspot.com",
  messagingSenderId: "418337159166",
  appId: "1:418337159166:web:2a0b4377594ae26b50b2af",
  measurementId: "G-DBCE2PTJGK",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

export {
  database,
  ref,
  set,
  get,
  child,
  firestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  where,
  auth,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider
};

// const firebaseConfig = {
//   apiKey: "AIzaSyB6PbcLAwtAvia_-h9RMOknSjW7ueKB-kY",
//   authDomain: "ziyon-ios-72f4b.firebaseapp.com",
//   projectId: "ziyon-ios-72f4b",
//   storageBucket: "ziyon-ios-72f4b.appspot.com",
//   messagingSenderId: "593461080038",
//   appId: "1:593461080038:web:ae3d6403f5c1443d493027",
//   measurementId: "G-2H6HXG96D7",
// };

// import { getAnalytics } from "firebase/analytics";
// const analytics = getAnalytics(app);
