import React from "react";
import "./styles.css"

function Home() {
  return (
    <div  className="home-content">
      <h1>Dashboard Home</h1>
    </div>
  );
}

export default Home;